import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import SubmitButton from 'elements/Button/SubmitButton'
import Form from 'elements/Form/Form'
import {
  FormErrorMessage,
  FormControl,
  InputGroup,
  Icon,
  Stack,
  InputLeftElement,
  Input
} from '@chakra-ui/core'

export default function ConfirmSignupForm ({ onSubmit }) {
  const { handleSubmit, errors, register, formState } = useForm()

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={5} mt={5}>
        <FormControl isInvalid={errors.code}>
          <InputGroup>
            <InputLeftElement><Icon name="secret" color="gray.300" /></InputLeftElement>
            <Input
              name="code"
              placeholder="code"
              ref={register({ required: 'Field is required' })}
            />
          </InputGroup>
          <FormErrorMessage>
            {errors.code && errors.code.message}
          </FormErrorMessage>
        </FormControl>

        <SubmitButton
          isLoading={formState.isSubmitting}
        >Confirm</SubmitButton>
      </Stack>
    </Form>
  )
}
ConfirmSignupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
}
