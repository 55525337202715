import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { isValidEmail, isValidPassword } from 'utils/formValidator'
import SubmitButton from 'elements/Button/SubmitButton'
import Form from 'elements/Form/Form'
import {
  FormHelperText,
  FormErrorMessage,
  FormControl,
  InputGroup,
  Icon,
  Stack,
  InputLeftElement,
  Input
} from '@chakra-ui/core'

export default function SignupForm ({ onSubmit }) {
  const { handleSubmit, errors, register, formState } = useForm()

  const hashes = ['1960592142', '1161706461', '868097025', '2140255135', '891724601', '1501369296']
  const validateCode = function (value) {
    var hash = 0
    for (var i = 0; i < value.length; i++) {
      var char = value.charCodeAt(i)
      hash = ((hash << 5) - hash) + char
      hash = hash & hash // Convert to 32bit integer
    }
    let error
    if (!hashes.includes(String(hash))) {
      error = 'Code is required'
    }
    return error || true
  }

  function validatePassword (value) {
    let error
    if (!value) {
      error = 'Password is required'
    } else if (!isValidPassword(value)) {
      error = 'Password must be 8 chars or longer'
    }
    return error || true
  }
  function validateEmail (value) {
    let error
    if (!value) {
      error = 'Email is required'
    } else if (!isValidEmail(value)) {
      error = 'Email is invalid'
    }
    return error || true
  }
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={5} mt={5}>
        <FormControl isInvalid={errors.code}>
          <InputGroup>
            <InputLeftElement><Icon name="star" color="gray.300" /></InputLeftElement>
            <Input
              name="code"
              placeholder="invite code"
              ref={register({ validate: validateCode })}
            />
          </InputGroup>
          <FormErrorMessage>
            {errors.code && errors.code.message}
          </FormErrorMessage>

        </FormControl>
        <FormControl isInvalid={errors.email}>
          <InputGroup>
            <InputLeftElement><Icon name="email" color="gray.300" /></InputLeftElement>
            <Input
              name="email"
              placeholder="your email"
              ref={register({ validate: validateEmail })}
            />
          </InputGroup>
          <FormErrorMessage>
            {errors.email && errors.email.message}
          </FormErrorMessage>

        </FormControl>
        <FormControl isInvalid={errors.password}>
          <InputGroup>
            <InputLeftElement><Icon name="lock" color="gray.300" /></InputLeftElement>
            <Input
              name="password"
              type="password"
              placeholder="create a password"
              ref={register({ validate: validatePassword })}
            />
          </InputGroup>
          <FormHelperText>At least 8 characters mix of lowercase, uppercase and number</FormHelperText>
          <FormErrorMessage>
            {errors.password && errors.password.message}
          </FormErrorMessage>
        </FormControl>
        <SubmitButton
          isLoading={formState.isSubmitting}
        >Signup</SubmitButton>
      </Stack>
    </Form>
  )
}
SignupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
}
