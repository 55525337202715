import React, { useState, useContext } from 'react'
import AuthContext from 'contexts/AuthContext'
import Subtitle from 'elements/Typography/Subtitle'
import Title from 'elements/Typography/Title'
import SignupForm from 'views/Auth/SignupForm'
import ConfirmSignupForm from 'views/Auth/ConfirmSignupForm'
import AltActionButton from 'elements/Button/AltActionButton'
import { navigate } from 'gatsby'
import {
  Text,
  useToast
} from '@chakra-ui/core'
import Auth from '@aws-amplify/auth'
import { WELCOME_ROUTE, LOGIN_ROUTE } from 'config/routes'

export default function SignupPage () {
  const [registerData, setRegisterData] = useState(false)
  const { setAuthenticated } = useContext(AuthContext)
  const toast = useToast()

  const notice = {
    title: 'Ooops!',
    description: 'There was a problem signing up - please reach out to support',
    status: 'error',
    isClosable: true
  }

  const onSignup = async ({ email, password }) => {
    try {
      await Auth.signUp({
        username: email,
        password
      })
      setRegisterData({ email, password })
    } catch (e) {
      toast({ ...notice, description: e.message })
    }
  }

  const onRegister = async ({ code }) => {
    const { email, password } = registerData
    try {
      await Auth.confirmSignUp(email, code)
      await Auth.signIn(email, password)
      setAuthenticated(true)
      navigate(WELCOME_ROUTE)
    } catch (e) {
      toast({ ...notice, description: e.message })
    }
  }
  return (
    <>

      <Title>Get started</Title>
      <Subtitle>Sign up for free. No credit card required.</Subtitle>
      {!registerData ? (
        <Text>
          Note: Our registration is currently on an invite basis only. Please
          join our newsletter to get on the waiting list.
        </Text>
        // <Text>
        //   To register please signup with your email address and a secure
        //    password and we'll send you a confirmation code by email to enter in
        //   the next step.
        //  </Text>
      ) : (
        <Text>
          We&apos;ve sent a confirmation code to {registerData.email}. Please enter
          it here.
        </Text>
      )}

      {registerData ? (
        <ConfirmSignupForm onSubmit={onRegister}/>
      ) : (
        <SignupForm onSubmit={onSignup}/>
      )}
      <AltActionButton to={LOGIN_ROUTE}>Already have an acccount? Go to login instead</AltActionButton>
    </>
  )
}
